<template>
  <div>
    <v-row dense>
      <v-col cols="12">
<!--          <v-card-title>-->
        <!--            <v-row>-->
<!--              <v-col class="d-flex align-center justify-start" cols="8"-->
<!--              ><h5 class="d-flex align-left">{{ $t("patients") }}</h5>-->
<!--              </v-col>-->
<!--              <v-col cols="4">-->
<!--                <v-text-field-->
<!--                    v-model="search"-->
<!--                    append-icon="mdi-magnify"-->
<!--                    :label="$t('search-patient')"-->
<!--                    single-line-->
<!--                    hide-details-->
<!--                    filled-->
<!--                    dense-->
<!--                    background-color="#E8E8E8"-->
<!--                    rounded-->
<!--                    class="table-search-button"-->
<!--                />-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-card-title>-->
          <v-data-table
              class="ma-3 text-xs-center data_table"
              :loading="loading"
              :headers="headers"
              :no-data-text="$t('no-data-available')"
              :loading-text="$t('loading')"
              :headers-length="headers.length"
              :search="search"
              :items="user"
              :expanded.sync="expanded"
              :single-expand="true"
              :items-per-page="-1"
              sort-by="id"
              item-key="id"
              hide-action
              fixed-header
              dense
              style="cursor: pointer"
              @click:row="userDetails"
              :height="pageHeight(140)"
              :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
          >
            <!-- <template v-slot:[footer.prepend="{}">
              <v-btn
                v-if="roleName === 'HOSPITAL_ADMIN'"
                class="table-create-button"
                label="Test"
                @click="dialog = true"
              >
                <span>{{ $t("addNewPatientTooltip") }}</span>
              </v-btn>
            </template> -->
            <template v-slot:expanded-item = "{ headers }">
              <td class="pa-0" :colspan="headers.length" style="width: 100% !important;">
                <!--                  <MedicalHealthSurveys @editTemplate="editTemplate" v-if="dialog1" style="width: 100%;" :dialog="dialog1" :patientDetails="patientDetails" >-->
                <!--                    <template  v-slot:surveys></template>-->
                <!--                  </MedicalHealthSurveys>-->
                <v-data-table
                    hide-default-footer
                    hide-default-header
                    dense
                    :headers-length="headerForUser.length"
                    :headers="headerForUser"
                    :items="detailsPatient"
                >
                  <!--        <template v-slot:[`group.header`]="{ group, headers, items, toggle }">-->
                  <!--          <td @click="toggle" style=" text-align: left !important;" :colspan="headers.length + 1">-->
                  <!--            <v-icon @click.stop="editTemplate(group)">mdi-pencil</v-icon>-->
                  <!--            <span style="font-weight: bold">{{ titleMap.get(group) }}</span>-->
                  <!--            <v-chip pill x-small color="primary" class="ma-2 text&#45;&#45;black">{{ items.length }}</v-chip>-->
                  <!--            <span style="font-weight: bold">{{ descriptionMap.get(group) }}</span>-->
                  <!--            <span style="float: right;" class="mt-2">-->
                  <!--              <span @click="isOpenFunction()" style="font-weight: bold">{{ statusMap.get(group) === 1 ? 'Active' : 'Inactive' }}</span>-->
                  <!--									<v-icon  @click="isOpenFunction()" color="primary" v-if="isOpen" >mdi-arrow-down-drop-circle</v-icon>-->
                  <!--									<v-icon  @click="isOpenFunction()" color="primary" v-else >mdi-arrow-up-drop-circle</v-icon>-->
                  <!--								</span>-->
                  <!--          </td>-->
                  <!--        </template>-->
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{ $i18n.locale === 'en' ? item.mentalHealthCategoryTitle : item.mentalHealthCategoryTitleEsp }}
                      </td>
                      <td>
                        {{ item.templateName}}
                      </td>
                      <td>
                        <v-chip pill x-small color="primary" class="ma-2 text--black">{{ item.surveyRecurrencesList.length }}</v-chip>
                      </td>
                      <td>
                        {{ item.submitAtDate}}
                      </td>
                      <td>
                        <v-icon @click.stop="editSurveyTemplate(item)" >mdi-pencil</v-icon>
                      </td>
                      <td>
                        {{ item.status === 1 ? 'Active' : 'Inactive'}}
                      </td>
                      <td @click="openDialog(item)">
                        <v-icon>mdi-eye</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-if="roleName === 'DOCTOR'" v-slot:[`footer.prepend`]>
              <v-btn
                  class="table-create-button"
                  label="AddDialog"
                  @click="assigneMedicalTeamFlag = true"
              >
                <img src="@/assets/icons_v2/mentalHealthWhite.svg" class="mr-2" style="width: 20px; height: 20px;">
                <span> {{ $t("assign-mental-health-template") }}</span>
              </v-btn>
            </template>
            <template v-slot:[`item.statusShortDescription`]="{ item }">
              <img class="my-1" :src="patient_icon(item.status)" height="24px" />
            </template>
            <template v-slot:[`item.fullname`]="{ item }">
              {{item.firstName}} {{item.lastName}}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{item.gender === 'M' ? 'Male' : 'Female'}}
            </template>
            <template v-slot:[`item.age`]="{ item }">
              {{calculateAge(item.birthdate)}}
            </template>
          </v-data-table>
        <assign-mental-health-template :edit="edit" @close="assigneMedicalTeamFlag=false" :dialog="assigneMedicalTeamFlag"></assign-mental-health-template>
        <MedicalHealthSurveys @editTemplate="editTemplate" v-if="dialog1" style="width: 100%;" :dialog="dialog1" @close="dialog1 = false" :patientDetails="surveyTemplateDetails" ></MedicalHealthSurveys>
        <EditMedicalHealthAssigne  :dialog="edit" @close="edit = false"></EditMedicalHealthAssigne>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AssignMentalHealthTemplate from '@/views/dialogs/AssignMentalHealthTemplate';
import { patientSecondary, patientBlack, videocallBlack, patientWhite } from '@/assets';
import MedicalHealthSurveys from '@/views/dialogs/MedicalHealthSurveys';
// import { AsYouType } from 'libphonenumber-js';
import EditMedicalHealthAssigne from '@/views/dialogs/EditMedicalHealthAssigne.vue';
import { permissions } from '../../../utils/calls';
// / eslint-disable no-mixed-spaces-and-tabs /;

export default {
	components: {
		AssignMentalHealthTemplate,
		MedicalHealthSurveys,
		EditMedicalHealthAssigne,
	},
  data () {
    return {
      search: '',
      assigneMedicalTeamFlag: false,
      loading: true,
      expanded: [],
      surveyTemplateDetails: [],
      dialog1: false,
      enable: false,
      dialog: false,
      isOpen: true,
      edit: false,
      detailsPatient: [],
      filteredUsers: [],
      headerClass: 'black_inner--text !important;',
      patient_secondary: patientSecondary,
      patient_black: patientBlack,
      videocallBlack: videocallBlack,
      patient_white: patientWhite,
    };
  },
  computed: {
    ...mapState({
      usersFromRepo: (state) => state.users.surveyUser,
      patientsFromRepo: (state) => state.hospitals.patients,
      relatedPatients: (state) => state.hospitals.relatedPatients,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
      detailsFromPatient: (state) => state.mentalhealth.surveyPatentDetails,
      surveyRecurrenceId: state => state.mentalhealth.surveyRecurrenceId,
      recurrence: state => state.mentalhealth.recurrence,
      userData: (state) => state.authentication.userData,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      hospitalId: 'authentication/getHospitalId',
      myMedicalTeam: 'authentication/getMyMedicalTeam',
      pageHeight: 'pageHeight',
      delay: 'authentication/getDelay',
    }),
    headerForUser () {
      return [
        {
          text: 'Category',
          value: 'mentalHealthCategoryTitle',
          align: 'center',
        },
        {
          text: 'Name',
          value: 'templateName',
          align: 'center',
        },
        {
          text: '',
          value: 'action',
        },
        {
          text: 'Date',
          align: 'center',
          value: 'submitAtDate',
        },
        {
          text: '',
          value: 'action',
        },
        {
          text: 'Status',
          align: 'center',
          value: 'status',
        },
        {
          text: '',
          value: 'action',
        },
      ];
    },
    user () {
      return this.usersFromRepo ?? [];
    },
    patientDetails () {
      if (this.enable) {
        if (this.detailsPatient.length > 0) {
          const temp = [];
          this.detailsPatient.forEach(element => {
            element.surveyRecurrencesList.forEach(item => {
              temp.push(item);
            });
          });
          return temp;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    testHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return window.innerHeight - 320;
        case 'sm':
          return window.innerHeight - 300;
        case 'md':
          return window.innerHeight - 300;
        case 'lg':
          return window.innerHeight - 280;
        case 'xl':
          return window.innerHeight - 280;
        default:
          return 0;
      }
    },
    itemsForUser () {
      if (this.detailsFromPatient) {
        return this.detailsFromPatient;
      } else {
        return [];
      }
    },
    headers () {
      var headers = [];
      headers = [
        { text: this.$t('header-status'), sortable: false, value: 'statusShortDescription', class: 'color_header pl-9' },
        { text: this.$t('header-full-name'), sortable: false, value: 'fullname', class: 'color_header pl-9' },
        { text: this.$t('header-sex'), sortable: false, value: 'gender', class: 'color_header' },
        { text: this.$t('header-age'), sortable: false, value: 'age', class: 'color_header' },
        { text: this.$t('header-phone'), sortable: false, value: 'phone', class: 'color_header' },
        { text: this.$t('header-email'), sortable: false, value: 'email', class: 'color_header' },
      ];
      return headers;
    },
  },
  methods: {
    editTemplate () {
      this.edit = true;
      this.assigneMedicalTeamFlag = true;
    },
    editSurveyTemplate (item) {
      this.$store.dispatch('mentalhealth/SurveysAssignedById', item.id).then(() => {
        this.edit = true;
      });
    },
    openDialog (val) {
      this.dialog1 = true;
      this.surveyTemplateDetails = [val];
    },
    calculateAge (dateString) {
      var today = new Date();
      var birthDate = new Date(dateString?.split('T')[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    userDetails (row) {
      this.$store.dispatch('mentalhealth/getSurveyForPatient', row.id).then(() => {
        this.enable = true;
        this.detailsPatient = this.detailsFromPatient;
        if (this.expanded.length === 0) {
          this.expanded.push(row);
        } else {
          this.expanded = [];
          this.expanded.push(row);
        }
      });
    },
    async getPatients () {
      const hospitalId = this.userData.hospitalAssignedData.id; // we take the authenticated hospitalId from store
      await this.$store.dispatch('users/getSurveyPatients', hospitalId);
      this.loading = false;
    },
    async getVerifiers () {
      const header = {
        domainValue: 'USER_STATUS',
      };
      await this.$store.dispatch('verifiers/getVerifiersForDomain', header);
    },
    closeDialog () {
      this.dialog = false;
      this.getPatients();
    },
    success (res) {
      this.dialog = false;
      this.getPatients();
      if (res.resFlag) {
        this.$store.commit('alerts/add', {
          id: 'updatedStaffDetails',
          type: 'success',
          color: 'main_green',
          // message: res.msg,
          message: this.$t('success'),
        });
        this.$store.dispatch('alerts/clearWithDelay', this.delay);
      } else {
        this.$store.commit('alerts/add', {
          id: 'updatedStaffDetails',
          type: 'error',
          color: 'main_red',
          // message: res.msg,
          message: this.$t('failed'),
        });
        this.$store.dispatch('alerts/clearWithDelay', this.delay);
      }
    },
    patient_icon (status) {
      switch (status) {
        case 1:
          return this.patient_black;
        default:
          return this.patient_secondary;
      }
    },
    async sendActivationEmail (item) {
      const sendValidationEmail = { email: item.email };
      await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
          .then(res => {
            if (res.resFlag) {
              this.$store.commit('alerts/add', {
                id: 'addedAdmin',
                type: 'success',
                color: 'main_green',
                // message: res.msg,
                message: this.$t('success'),
              });
              this.$store.dispatch('alerts/clearWithDelay', this.delay);
            } else {
              this.$store.commit('alerts/add', {
                id: 'errorAddingAdmin',
                type: 'error',
                color: 'main_red',
                // message: res.msg,
                message: this.$t('failed'),
              });
              this.$store.dispatch('alerts/clearWithDelay', this.delay);
            }
          });
    },
    async initiateCall (item, videotrack) {
      if (await permissions()) return;
      const type = videotrack ? 'video' : 'audio';
      const body = {
        UserId: item.id,
        MessageToSend: `${item.firstName} ${item.lastName} has started ${type} call`,
        NeedVideoTrack: videotrack,
      };
      await this.$store.dispatch('notifications/initVideoCall', body).then(async (res) => {
        this.loading = true;
        await this.goToRoom(res.data.appointmentData, videotrack);
        this.loading = false;
      });
    },
    async goToRoom (item, videotrack) {
      this.$router.push({
        name: 'VideoRoom',
        params: {
          id: `${item.videoRoomId}`,
          userId: `${item.patientId}`,
          appointmentId: item.appointmentId ? item.appointmentId : item.id,
          token: localStorage.sessionToken,
          needVideoRecording: item.needVideoRecording,
          videotrack: videotrack,
        },
      });
    },
  },
  async mounted () {
    await this.getVerifiers();
    await this.getPatients();
    if (this.recurrence && this.recurrence.length === undefined && this.surveyRecurrenceId !== null) {
      this.dialog1 = true;
      var surveyRecurrence = [];
      surveyRecurrence = {
        surveyRecurrencesList: [this.recurrence],
      };
      this.surveyTemplateDetails = [surveyRecurrence];
      this.$store.dispatch('mentalhealth/updateFiltersRecurrence', null);
    }
  },
};
</script>
<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
