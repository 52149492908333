<template>
  <v-dialog v-model="dialog">
  <template>
        <v-card flat style="overflow: hidden" >
          <v-card-title class="secondary py-1" style="height: 40px">
            <span class="dialog-title">{{ $t("survey-dialog") }}</span>
            <v-spacer />
            <v-icon small color="primary" @click="$emit('close')"
            >mdi-window-close</v-icon
            >
          </v-card-title>
          <v-card-text color="primary" class="mt-3" >
<!--            <v-data-table-->
<!--                hide-default-footer-->
<!--                dense-->
<!--                :headers-length="headerForUser.length"-->
<!--                :headers="headerForUser"-->
<!--                :items="patientDetails[0].surveyRecurrencesList"-->
<!--            >-->
<!--              <template v-slot:item="{ item, index }">-->
<!--              <tr @click="openQuestions(item, index)">-->
<!--                <td class="d-flex justify-start align-center">{{ item.submitAtDate }}</td>-->
<!--                <td><v-checkbox disabled hide-details dense v-model="item.isCompleted"></v-checkbox></td>-->
<!--&lt;!&ndash;                <td @click="showQuestions(item)"><v-icon>mdi-eye</v-icon></td>&ndash;&gt;-->
<!--              </tr>-->
<!--              </template>-->
<!--            </v-data-table>-->
            <v-data-table
                :headers="headerForUser"
                :items="patientDetails[0].surveyRecurrencesList"
                dense
                fixed-header
                hide-default-footer
                :expanded.sync="expanded"
                item-key="id"
                show-expand
                class="elevation-0"
                :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
            >
              <template v-slot:item.submitAtDate="{ item }" >
                <p :style="!item.isCompleted ? 'color: red' : ''" class="my-2">{{ item.submitAtDate}}</p>
              </template>
              <template v-slot:item.isCompleted="{ item }" >
                <p :style="!item.isCompleted ? 'color: red' : ''" class="my-2">{{ item.isCompleted}}</p>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td  class="pa-0" :colspan="headers.length">
                  <div style="  height: 500px; overflow-y: scroll">
            <v-card dense class=" mx-4 secondary elevation-0" v-for="(question, index) in item.surveyQuestionsList" :key="index">
              <v-row class="ma-0">
                <v-col cols="1 ml-3">
                  <v-avatar
                      color="primary"
                      size="30"
                  ><span style="color: white">{{index + 1}}</span>
                  </v-avatar>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                      hide-details dense
                      disabled
                      style="font-weight: bold; font-size: 20px"
                      v-model="question.question"
                  ></v-text-field>
                </v-col>
              </v-row>
<!--              <v-radio-group-->
<!--                  row-->
<!--                  v-model="question.answerTypeId"-->
<!--                  class="ml-10"-->
<!--                  disabled-->
<!--              >-->
<!--                <v-radio-->
<!--                    v-for="(verifiers, index) in surveyAnswerType" :key="index"-->
<!--                    :label="$i18n.locale === 'en' ? verifiers.shortDescription : verifiers.shortDescriptionEsp"-->
<!--                    :value="verifiers.lowValue"-->
<!--                ></v-radio>-->
<!--              </v-radio-group>-->
              <div>
                <div class="ma-3">
                  <v-row>
                    <v-col>
                      <v-data-table
                          :headers="headersAnswer"
                          :items="question.offeredAnswersList"
                          sort-by="calories"
                          :no-data-text="$t('no-option-entered')"
                          :items-per-page="-1"
                          hide-default-footer
                          hide-default-header
                          dense
                          class="elevation-0"
                          :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td :style="item.triggerNotification ? 'color:red' : ''"> <v-checkbox disabled dense class="mt-1" v-model="item.selectedAsAnswer"></v-checkbox></td>
                            <td :style="item.triggerNotification ? 'color:red' : ''">{{item.answer}}</td>
<!--                            <td><v-icon style="color: red" v-if="item.triggerNotification">mdi-account-alert</v-icon></td>-->
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card>
                  </div>
                </td >
              </template>
            </v-data-table>
          </v-card-text>
<!--          <surveyQuestions v-if="show" :show = show @close="show = false" :showQuestionForSurvey="showQuestionForSurvey"></surveyQuestions>-->
        </v-card>
<!--      </v-data-table>-->
<!--    </slot>-->
  </template>
<!--    <EditMedicalHealthAssigne :dialog="edit"></EditMedicalHealthAssigne>-->
  </v-dialog>
</template>
<script>
// import draggable from 'vuedraggable';
import { mapState } from 'vuex';
import { formatterFilter } from '@/utils/luxon-formater';
// import surveyQuestions from '@/views/dialogs/SurveyQuestions';
// import EditMedicalHealthAssigne from '@/views/dialogs/EditMedicalHealthAssigne.vue';
export default {
	props: ['dialog', 'patientDetails'],
	components: {
		// draggable,
		// EditMedicalHealthAssigne,
		// surveyQuestions,
	},
	data () {
		return {
			edit: false,
			surveyDetails: {},
			show: false,
			surveyAnswerType: [],
			expanded: [],
			showQuestionForSurvey: {},
			isOpen: true,
			titleMap: new Map(),
			statusMap: new Map(),
			descriptionMap: new Map(),
			timeLocalization: this.$i18n.locale,
		};
	},
	computed: {
		...mapState({
			verifiers: (state) => state.verifiers.verifiers,
			surveyById: (state) => state.mentalhealth.survey,
			verifires: (state) => state.verifiers.verifiers,
		}),
		headersAnswer () {
			return [
				{ text: 'Trigger alert on this', value: 'checkbox', sortable: true, width: '30%' },
				{ text: 'Name', value: 'answer', width: '50%' },
				// { text: 'Actions', value: 'actions', sortable: true, width: '20%' },
			];
		},
		headerForUser () {
			return [
				{
					text: this.$t('date'),
					value: 'submitAtDate',
					width: '80%',
				},
				{
					text: this.$t('is-completed'),
					value: 'isCompleted',
					width: '10%',
				},
				// {
				// 	text: 'Action',
				// 	value: 'action',
				// 	align: 'center',
				// },
			];
		},
	},

	watch: {
		async patientDetails () {
			await this.findSurvey();
			await this.closeAll();
		},
	},
	mounted () {
		this.findSurvey();
		this.closeAll();
		this.manageMentalHealthDialog();
	},
	methods: {
		async manageMentalHealthDialog () {
			const header2 = { domainsList: 'SURVEY_ANSWER_TYPE' };
			await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header2).then(() => {
				// remove number rsnge
				this.surveyAnswerType = [];
				this.verifires.forEach(item => {
					if (item.lowValue !== 4) { this.surveyAnswerType.push(item); }
				});
				this.surveyCreate = true;
			});
		},
		closeAll () {
			Object.keys(this.$refs).forEach(k => {
				if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
					this.$refs[k].$el.click();
				}
			});
		},
		editTemplate (id) {
			this.$store.dispatch('mentalhealth/SurveysAssignedById', id).then(() => {
				this.edit = true;
			});
		},
		dateFormatter (item) {
			return formatterFilter((item.generatedAt), 'datetime_short', this.timeLocalization);
		},
		isOpenFunction () {
			this.isOpen = !this.isOpen;
			return this.isOpen;
		},
		findSurvey () {
			this.patientDetails.forEach(item => {
				this.titleMap.set(item.surveyId, item.templateName);
				this.statusMap.set(item.surveyId, item.status);
				this.descriptionMap.set(item.surveyId, item.templateDescription);
			});
		},
	},
};
</script>
