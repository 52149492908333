<template>
  <v-dialog
      v-model="dialog"
      width="1000"
      v-if="detailsFromPatient[0] !== undefined"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      style="background-color: blue; border-radius: 30px !important"
  >
    <v-card flat style="overflow: hidden">
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">{{$t('medicalTeam')}}</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('close')"
        >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-card-text color="primary" class="mt-3" >
        <v-form dense>
          <v-row dense class="pt-5">
            <v-col cols="5"
            >
              <v-text-field
                  dense
                  readonly
                  disabled
                  outlined
                  hide-details
                  :value="assignedSurvay.templateName"
                  :label="$t('assign-mental-health-template')"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
            >
              <v-select
                  :items="verifiersForSelect"
                  hide-details
                  item-value="lowValue"
                  :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                  v-model="RecurrenceRuleForSurvey"
                  dense
                  :label="$t('recurrence')"
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="3"
            >
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      dense
                      hide-details
                      outlined
                      :label="$t('start-date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    range
                    color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card>
      </v-card>

      <v-row dense class="d-flex justify-end py-3 mx-1" style="overflow: hidden">
            <span cols="12" lg="6" md="6" sm="12" >
              <v-btn class="table-cancel-button" @click="$emit('close')">
                <span color="black--text">{{ $t("cancel") }}</span>
              </v-btn>
            </span>
        <!--        save button-->
        <span cols="12" lg="6" md="6" sm="12">
              <v-btn
                  class="table-button mx-1"
                  :loading="loading"
                  @click="save"             >
                <span>{{ $t("save") }}</span>
              </v-btn>
            </span>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { doctorBlack, nurseBlack } from '@/assets';
export default {
	props: ['dialog', 'data', 'deniedPatients', 'edit'],
	data () {
		return {
			date: null,
			menu: false,
			modal: false,
			menu2: false,
			loader: null,
			loading: false,
			medicalTemaName: null,
			endDateFlag: false,
			selectedStaff: [],
			relatedPatients: [],
			staff: [],
			doctors: [],
			disableSaveDate: true,
			RecurrenceRuleForSurvey: null,
			starterArray: [],
			selectedTamplate: null,
			selectedPatient: undefined,
			medicalTeamTitle: '',
			doctor_black: doctorBlack,
			nurse_black: nurseBlack,
			filteredUsers: [],
			disableSave: true,
			disableSaveRecurrence: true,
			searchPatient: null,
			searchMedicalTeam: null,
			primaryDoctor: null,
			patients: [],
			selected: [],
			primaryDoctorSelected: [],
			staffSelected: [],
			selectedRows: [],
		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			relatedUsers: (state) => state.hospitals.users,
			verifiers: (state) => state.verifiers.verifiers,
			relatedPatientsTemp: (state) => state.hospitals.relatedPatients,
			usersFromRepo: (state) => state.users.users,
			detailsFromPatient: (state) => state.mentalhealth.surveyPatentDetails,
			assignedSurvay: state => state.mentalhealth.surveyAssignedById,
		}),
		staffData () {
			return this.staff.filter(s => { return s.id !== this.primaryDoctor && s.status === 1; });
		},
		verifiersForSelect () {
			if (this.verifiers) {
				return this.verifiers;
			} else {
				return [];
			}
		},
		checkValidity () {
			if (this.medicalTemaName === null) {
				return true;
			}
			if (this.staffSelected.length === 0) {
				return true;
			}
			if (this.primaryDoctorSelected.length === 0) {
				return true;
			}
			return false;
		},
	},
	watch: {
		medicalTemaName (val) {
			if (val !== null) {
				this.disableSave = false;
			} else {
				this.disableSave = true;
			}
		},
		RecurrenceRuleForSurvey (val) {
			if (val !== null) {
				this.disableSaveRecurrence = false;
			} else {
				this.disableSaveRecurrence = true;
			}
		},
		date (val) {
			if (val !== null) {
				this.disableSaveDate = false;
			} else {
				this.disableSaveDate = true;
			}
		},
		dialog (val) {
			this.RecurrenceRuleForSurvey = this.assignedSurvay.recurrenceRuleForSurvey;
			this.date = [this.assignedSurvay.startDate, this.assignedSurvay.endDate];
			if (val) {
				const header = { domainsList: 'SURVEY_RECURRENCER_RULE' };
				this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
			}
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];
				await this.manageMedicalTeam()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
	},
	methods: {
		save () {
			// var tempArray = this.selected.map(obj => obj.id);
			var obj = {
				body: {
					templateName: this.assignedSurvay.templateName,
					templateDescription: this.assignedSurvay.templateDescription,
					recurrenceRuleForSurvey: this.RecurrenceRuleForSurvey,
					StartDate: this.date[0],
					EndDate: this.date[1] ? this.date[1] : null,
					status: this.assignedSurvay.status,
				},
				header: this.assignedSurvay.id,
			};
			this.$store.dispatch('mentalhealth/assigneMentalHealthEdit', obj).then(() => {
				this.$emit('close');
			});
		},
		toggleSelection (keyID) {
			if (this.selectedRows.includes(keyID)) {
				this.selectedRows = this.selectedRows.filter(
					selectedKeyID => selectedKeyID !== keyID,
				);
			} else {
				this.selectedRows.push(keyID);
			}
		},
	},
};
</script>
