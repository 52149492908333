var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"secondary py-1",staticStyle:{"height":"40px"}},[_c('span',{staticClass:"dialog-title"},[_vm._v(_vm._s(_vm.$t("survey-dialog")))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-window-close")])],1),_vm._v(" "),_c('v-card-text',{staticClass:"mt-3",attrs:{"color":"primary"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headerForUser,"items":_vm.patientDetails[0].surveyRecurrencesList,"dense":"","fixed-header":"","hide-default-footer":"","expanded":_vm.expanded,"item-key":"id","show-expand":"","footer-props":{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.submitAtDate",fn:function({ item }){return [_c('p',{staticClass:"my-2",style:(!item.isCompleted ? 'color: red' : '')},[_vm._v(_vm._s(item.submitAtDate))])]}},{key:"item.isCompleted",fn:function({ item }){return [_c('p',{staticClass:"my-2",style:(!item.isCompleted ? 'color: red' : '')},[_vm._v(_vm._s(item.isCompleted))])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('div',{staticStyle:{"height":"500px","overflow-y":"scroll"}},_vm._l((item.surveyQuestionsList),function(question,index){return _c('v-card',{key:index,staticClass:"mx-4 secondary elevation-0",attrs:{"dense":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"1 ml-3"}},[_c('v-avatar',{attrs:{"color":"primary","size":"30"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(index + 1))])])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{staticStyle:{"font-weight":"bold","font-size":"20px"},attrs:{"hide-details":"","dense":"","disabled":""},model:{value:(question.question),callback:function ($$v) {_vm.$set(question, "question", $$v)},expression:"question.question"}})],1)],1),_vm._v(" "),_c('div',[_c('div',{staticClass:"ma-3"},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersAnswer,"items":question.offeredAnswersList,"sort-by":"calories","no-data-text":_vm.$t('no-option-entered'),"items-per-page":-1,"hide-default-footer":"","hide-default-header":"","dense":"","footer-props":{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{style:(item.triggerNotification ? 'color:red' : '')},[_c('v-checkbox',{staticClass:"mt-1",attrs:{"disabled":"","dense":""},model:{value:(item.selectedAsAnswer),callback:function ($$v) {_vm.$set(item, "selectedAsAnswer", $$v)},expression:"item.selectedAsAnswer"}})],1),_vm._v(" "),_c('td',{style:(item.triggerNotification ? 'color:red' : '')},[_vm._v(_vm._s(item.answer))])])]}}],null,true)})],1)],1)],1)])],1)}),1)])]}}])})],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }